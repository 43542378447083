import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/resume" />} />
        <Route path="/resume" element={<ResumeRedirect />} />
      </Routes>
    </Router>
  );
}

const ResumeRedirect = () => {
  React.useEffect(() => {
    window.location.href = "/BrukAbuResume.pdf";
  }, []);

  return null;
};

export default App;